import axios from 'axios';
import { details as detailsKey } from '../constants/localStorageKey';

let isRefreshing = false;
let subscribers = [];

const refreshToken = () => {
  const details = localStorage[detailsKey]
    ? JSON.parse(localStorage[detailsKey]).details
    : '';

  const base = process.env.REACT_APP_BACKEND_API_URL || (process.env.NODE_ENV === 'development' ? 'http://collicare.test/api/v1/' : 'http://collicare-api.pinion.ph/api/v1/');
  const url = 'integration/generate-latest-token';

  return axios.get(base + url, {
    headers: {
      Authorization: `Bearer ${details.token}`,
    },
  });
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_INTEGRATION_API_URL || (process.env.NODE_ENV === 'development' ? 'http://collicare.test/api/v1/collicare/' : 'http://collicare-api.pinion.ph/api/v1/collicare/'),
});

instance.interceptors.request.use((config) => {
  const details = localStorage[detailsKey]
    ? JSON.parse(localStorage[detailsKey]).details
    : '';

  config.headers.Authorization = `Bearer ${details.integration_token}`;

  return config;
}, (error) => Promise.reject(error));

// eslint-disable-next-line max-len
instance.interceptors.response.use(response => {
  if (response.status === 404 && window.location.pathname !== '' && window.location.pathname !== '/') {
    // localStorage.removeItem('collicare-user-details');
    // window.location.href = '/';
    // window.location.reload();
  }
  return response && response.data ? response.data : response;
},
async (error) => {
  const status = error.response ? error.response.status : null;
  const { config } = error;
  if (status === 401) {
    const oldDetails = localStorage[detailsKey] ? JSON.parse(localStorage[detailsKey]) : {};
    let integrationToken = oldDetails.details.integration_token;

    if (!isRefreshing) {
      isRefreshing = true;

      refreshToken().then(({ data }) => {
        integrationToken = data.data;
        oldDetails.details.integration_token = data.data;
        localStorage[detailsKey] = JSON.stringify(oldDetails);

        subscribers.forEach(callback => callback(integrationToken));
        subscribers = [];
      });
    }

    const retryLastRequest = new Promise(resolve => {
      subscribers.push(async accessToken => {
        config.headers.Authorization = `Bearer ${accessToken}`;

        const response = await axios(config);
        isRefreshing = false;
        resolve(response.data);
      });
    });

    return retryLastRequest;
  }

  return Promise.reject(error.response && error.response.data ? error.response.data : error.response);
});

export default instance;
